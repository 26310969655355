import { Icon, Tooltip, Typography11, Typography16 } from "@slid/slid-ips";
import { useSLTButton } from "hooks/whisperSLTRealTime/useSLTButton";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useWhisperSLTStore from "store/useWhisperSLTStore";
import styled, { css, keyframes } from "styled-components";
import { SLTStopIcon } from "components/icons/SLTStopIcon";
import { useAppSelector } from "hooks";

const SmartLiveTextProgressInfoView = () => {
  const { transcriptChunk, isSTTActive, isManualAddingMode, resetTranscriptChunk, transcriptData } = useWhisperSLTStore();
  const { onClickSLTMoreButton, onClickSLTButton, SLTButtonStatus } = useSLTButton();

  const [realTimeTranscriptText, setRealTimeTranscriptText] = useState("");

  const { t } = useTranslation("SmartLiveText");

  useEffect(() => {
    if (transcriptData.length > 0) {
      resetTranscriptChunk();
    }
  }, [transcriptData]);

  const handleSLTMoreButton = (e) => {
    onClickSLTMoreButton(e);
  };

  const [displayText, setDisplayText] = useState("");
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    setRealTimeTranscriptText(transcriptChunk?.map((item) => item.text).join(" "));
  }, [transcriptChunk]);

  useEffect(() => {
    if (contentRef.current && realTimeTranscriptText) {
      const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
      const maxHeight = lineHeight * 3.1;

      contentRef.current.innerText = realTimeTranscriptText;

      if (contentRef.current.scrollHeight > maxHeight) {
        let start = 0;
        while (contentRef.current.scrollHeight > maxHeight && start < realTimeTranscriptText.length) {
          start += 10;
          contentRef.current.innerText = "..." + realTimeTranscriptText.slice(start);
        }
        setDisplayText("..." + realTimeTranscriptText.slice(start));
      } else {
        setDisplayText(realTimeTranscriptText);
      }
    }

    if (realTimeTranscriptText.trim() === "") {
      setDisplayText("");
    }
  }, [realTimeTranscriptText]);

  const { isExtensionMediaPermitted } = useAppSelector((state) => state.vdocs);

  // Hide component when no getting media permission
  if (!isExtensionMediaPermitted) {
    return null;
  }

  // Hide component when SLT is off (not active, in default state, not manual mode)
  if (!isSTTActive && SLTButtonStatus === "default" && !isManualAddingMode) {
    return null;
  }

  return (
    <Container>
      <TitleWrapper>
        <Typography16 text={!true ? t("SLTChecking") : t("SmartLiveText")} color={true ? `--gray17` : `--gray10`} weight={700} type={`span`} />
        <Tooltip title={t("Setting")}>
          <MoreIcon onClick={handleSLTMoreButton} icon={`more24`} width={24} height={24} color="--gray15" />
        </Tooltip>
      </TitleWrapper>

      <SLTContentText ref={contentRef} data-type={`slt-text`} data-content={isSTTActive ? t("SLTTyping") : t("SLTWaiting")} showPseudoData={true}>
        {displayText}
      </SLTContentText>

      <StopButton
        onClick={() => {
          onClickSLTButton();
        }}
      >
        <StopButtonIcon>
          <SLTStopIcon />
        </StopButtonIcon>
        <Typography11 text={t("StopButtonOnAutoSLTBlock")} color={`--gray17`} weight={500} />
      </StopButton>
    </Container>
  );
};

export default SmartLiveTextProgressInfoView;

const Container = styled.div`
  border-radius: 12px;
  border: 2px solid var(--Sub-color-Red-Red4, #fc9495);
  background: var(--SGray-Colors-white, #fff);
  box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.08);

  position: absolute;

  z-index: 1058;
  display: flex;
  flex-direction: column;

  padding: 16px 20px;
  align-items: flex-start;
  max-width: 700px;
  width: 87%;
  height: auto;
  bottom: 94px;
  left: 50%;
  transform: translateX(-50%);
  gap: 8px;
`;

const blink = keyframes`
  50% {
    border-color: var(--gray1);
  }
`;

const SLTContentText = styled.p<{ showPseudoData: boolean }>`
  color: var(--gray8);
  font-size: 1.5rem;
  line-height: 2.3rem;
  width: 100%;
  max-height: 69px;
  box-shadow: none;
  white-space: inherit;
  word-wrap: normal;
  resize: none !important;
  border: none;
  outline: none;
  padding: 0;
  background: transparent;

  min-height: 2.3rem;
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-word;

  ${({ showPseudoData }) =>
    showPseudoData &&
    css`
      color: var(--gray17);

      &::after {
        font-size: 1.3rem;
        padding-left: 8px;
        content: attr(data-content);
        color: var(--gray7);
        border-left: 1px solid var(--gray14);
        animation: ${blink} 0.8s infinite;
      }
    `}
`;

const StopButton = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--gray4);
  cursor: pointer;
  color: var(--gray17);

  &:hover {
    background: var(--red1);
    border: 1px solid var(--red1_opacity);
    transition: all 0.2s ease-in-out;

    p {
      color: var(--red7);
      transition: all 0.2s ease-in-out;
    }
  }
`;

const StopButtonIcon = styled.div`
  height: 12px;
  width: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;

  justify-content: space-between;
`;

const MoreIcon = styled(Icon)`
  cursor: pointer;
`;
